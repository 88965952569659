import * as ExcelJS from "exceljs";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Tooltip } from "primereact/tooltip";
import { apiCall } from "../../../Backend/Backend";
import { COLORSCHEMEA } from "../../../ColorSchema/ColorSchema";
import NumberFormatIn from "../../Common/NumberFormatIn";
import TopbarMost from "../../Common/TopbarMost";
import { useNotification } from "../../Notification/NotificationContext";
import { NewTheme } from "../../Theme/Theme";
import BalanceModal from "./TABS/Modal/BalanceModal";
import React from "react";

const height = window.innerHeight;
export default function SummaryNew({ selectedClient, goToOverview }) {
  const [loading, setLoading] = React.useState(false);
  const [summary, setSummary] = React.useState([]);
  const { addNotification } = useNotification();
  const [modalData, setModalData] = React.useState([]);
  const [totalCredit, setTotalCredit] = React.useState(0);
  const [totalDebit, setTotalDebit] = React.useState(0);
  const [totalDifference, setTotalDifference] = React.useState(0);

  React.useEffect(() => {
    load_masters_summary();
  }, []);

  const load_masters_summary = async () => {
    let sendData = {
      c_id: selectedClient.id,
    };

    const response = await apiCall("/load_masters_summary", sendData);
    // Calculate totals for positive and negative deposit amounts
    let creditTotal = 0;
    let debitTotal = 0;

    response.masters_summary.forEach((item) => {
      const depositAmount = parseFloat(item.balance) || 0;
      if (depositAmount >= 0) {
        creditTotal += depositAmount;
      } else {
        debitTotal += Math.abs(depositAmount);
      }
    });

    setTotalCredit(creditTotal);
    setTotalDebit(debitTotal);
    setTotalDifference(creditTotal - debitTotal);
    setLoading(false);
    setSummary(response.masters_summary);
  };

  const exportExcel = () => {
    // Create a new Excel workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("data");

    // Specify the actual columns you want to export
    const colsToExport = [
      "name",
      "username",
      "margin",
      "rent",
      "pl",
      "pl_margin",
    ];

    // Specify the display names for the headers
    const headerDisplayNames = [
      "Client",
      "Username",
      "Margin",
      "Rent",
      "Profit/Loss",
      "Net Profit/Loss",
    ];

    const headerRow = worksheet
      .addRow([
        "MASTERS SUMMARY (S) " + localStorage.getItem("server").toUpperCase(),
      ])
      .commit();
    worksheet.mergeCells(`A${worksheet.rowCount}:E${worksheet.rowCount}`);
    const mergedRange = worksheet.getCell(
      `A${worksheet.rowCount}:E${worksheet.rowCount}`
    );
    mergedRange.alignment = { horizontal: "center" };
    mergedRange.font = { bold: true, size: 16 };

    // Add empty rows between summary and data
    for (let i = 0; i < 2; i++) {
      worksheet.addRow([]);
    }

    const headerDisplayNamesRow = worksheet.addRow(headerDisplayNames);

    // Make the header row bold
    headerDisplayNamesRow.eachCell((cell) => {
      cell.font = { bold: true };
    });

    // Extract only the desired columns from transactionData
    const filteredData = summary.map((item) => {
      return colsToExport.reduce((acc, col) => {
        acc[col] = item[col];
        return acc;
      }, {});
    });

    // Add the filtered data to the worksheet
    filteredData.forEach((data) => {
      worksheet.addRow(Object.values(data));
    });

    // Set column width to 16 for all columns
    worksheet.columns.forEach((column) => {
      column.width = 16;
    });

    // Apply color to font based on the "profit_loss" condition
    const colIndex = colsToExport.indexOf("pl");
    const depositIndex = colsToExport.indexOf("pl_margin");
    const usernameIndex = colsToExport.indexOf("username");
    const marginIndex = colsToExport.indexOf("margin");
    const rentIndex = colsToExport.indexOf("rent");

    worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
      const plValue = row.getCell(colIndex + 1).value; // "pl" column value
      const depositValue = row.getCell(depositIndex + 1).value; // "deposit" column value
      const usernameValue = row.getCell(usernameIndex + 1).value; // "username" column value
      const marginValue = row.getCell(marginIndex + 1).value; // "username" column value
      const rentValue = row.getCell(rentIndex + 1).value; // "username" column value

      // Format for "profit_loss" column
      if (plValue !== null && rowNumber > 4) {
        const plColor =
          plValue > 0 ? NewTheme.greencolorExcel : NewTheme.redcolorExcel; // Green for positive, Red for negative
        row.getCell(colIndex + 1).font = {
          color: { argb: plColor },
          bold: true, // Make the font bold
        };

        // Convert "profit_loss" to number and set number format
        row.getCell(colIndex + 1).value =
          rowNumber > 4 ? Number(plValue) : plValue;
        row.getCell(colIndex + 1).numFmt =
          rowNumber > 4 ? "#,##0.00" : undefined; // Adjust the number format as needed
      }

      // Format for "deposit" column
      if (depositValue !== null && rowNumber > 4) {
        const depositColor =
          depositValue > 0 ? NewTheme.greencolorExcel : NewTheme.redcolorExcel; // Green for positive, Red for negative
        row.getCell(depositIndex + 1).font = {
          color: { argb: depositColor },
          bold: true, // Make the font bold
        };

        // Convert "deposit" to number and set number format
        row.getCell(depositIndex + 1).value =
          rowNumber > 4 ? Number(depositValue) : depositValue;
        row.getCell(depositIndex + 1).numFmt =
          rowNumber > 4 ? "#,##0.00" : undefined; // Adjust the number format as needed
      }

      // Format for "username" column
      if (usernameValue !== null && rowNumber > 4) {
        row.getCell(usernameIndex + 1).font = {
          color: { argb: NewTheme.MainColorExcel }, // Set the font color for the "username" column
          bold: true, // Make the font bold
        };
      }
      if (marginValue !== null && rowNumber > 4) {
        row.getCell(marginIndex + 1).value =
          rowNumber > 4 ? Number(marginValue) : marginValue;
        row.getCell(marginIndex + 1).numFmt =
          rowNumber > 4 ? "#,##0.00" : undefined;
      }

      if (rentValue !== null && rowNumber > 4) {
        row.getCell(rentIndex + 1).value =
          rowNumber > 4 ? Number(rentValue) : rentValue;
        row.getCell(rentIndex + 1).numFmt =
          rowNumber > 4 ? "#,##0.00" : undefined;
      }
    });

    var fileName =
      "MASTERS SUMMARY (S)" + localStorage.getItem("server").toUpperCase();

    // Create Excel file and trigger download
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAsExcelFile(buffer, fileName);
    });
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const cols = [
    { dataKey: "name", title: "Name" },
    { dataKey: "username", title: "Username" },
    { dataKey: "margin", title: "Margin" },
    { dataKey: "rent", title: "Rent" },
    { dataKey: "pl", title: "Profit/Loss" },
    { dataKey: "pl_margin", title: "Net Profit/Loss" },
  ];

  const exportPdf = () => {
    // Your column and data definitions (replace these with your actual data)
    const doc = new jsPDF({
      orientation: "landscape",
    });

    doc.setFontSize(16);
    doc.text("MASTERS SUMMARY", 15, 15);

    doc.setFontSize(12);
    doc.text(`Server: ${localStorage.getItem("server").toUpperCase()}`, 15, 25);

    doc.setFontSize(12);
    doc.text(
      `Admin: ${localStorage.getItem("adminsUsername").toUpperCase()}`,
      55,
      25
    );

    const headerStyles = {
      fillColor: "#4a6ba1",
      textColor: "#ffffff",
    };

    // Add content to the PDF using autoTable with modified data and custom header styles
    const tableStartY = 35; // Adjust the startY value based on the header size and layout
    doc.autoTable(cols, summary, { startY: tableStartY, headerStyles });

    var pdfName =
      "MASTERS SUMMARY (S)" + localStorage.getItem("server").toUpperCase();

    doc.save(pdfName + ".pdf");
  };

  const [filters, setFilters] = React.useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const value = filters["global"] ? filters["global"].value : "";
  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
  };

  const [visibleBalanceModal, setVisibleBalanceModal] = React.useState(false);
  const [position, setPosition] = React.useState("top");

  const showBalanceModal = (modal_data) => {
    setPosition("top");
    setVisibleBalanceModal(true);
    setModalData(modal_data);
  };

  const overviewBar = (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingRight: 20,
        gap: 10,
        fontWeight: "bold",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <div
          style={{
            // height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 10,
            minWidth: 160,
            backgroundColor: "#ffff",
            padding: "0 10px",
            borderRadius: 4,
          }}
          // onClick={() => show()}
        >
          <div style={{ fontSize: 12, color: COLORSCHEMEA.THEMEFONT }}>
            Credit
          </div>
          <div
            style={{
              fontSize: 16,
              marginTop: 3,
              backgroundColor: "#ffff",
              padding: "0 10px",
              borderRadius: 5,
              color: COLORSCHEMEA.GREENCOLOR,
            }}
          >
            <NumberFormatIn value={parseFloat(totalCredit).toFixed(2)} />
          </div>
        </div>
        <div
          style={{
            // height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 10,
            minWidth: 160,
            backgroundColor: "#ffff",
            padding: "0 10px",
            borderRadius: 4,
          }}
          // onClick={() => show()}
        >
          <div style={{ fontSize: 12, color: COLORSCHEMEA.THEMEFONT }}>
            Debit
          </div>

          <div
            style={{
              fontSize: 16,
              marginTop: 3,
              backgroundColor: "#ffff",
              padding: "0 10px",
              borderRadius: 5,
              color: COLORSCHEMEA.REDCOLOR,
            }}
          >
            <NumberFormatIn value={parseFloat(totalDebit).toFixed(2)} />
          </div>
        </div>
        <div
          style={{
            // height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 10,
            minWidth: 160,
            backgroundColor: "#ffff",
            padding: "0 10px",
            borderRadius: 4,
          }}
          // onClick={() => show()}
        >
          <div style={{ fontSize: 12, color: COLORSCHEMEA.THEMEFONT }}>
            Difference
          </div>
          <div
            style={{
              fontSize: 16,
              marginTop: 3,
              backgroundColor: "#ffff",
              padding: "0 10px",
              borderRadius: 5,
              color:
                totalDifference >= 0
                  ? COLORSCHEMEA.GREENCOLOR
                  : COLORSCHEMEA.REDCOLOR,
            }}
          >
            <NumberFormatIn value={parseFloat(totalDifference).toFixed(2)} />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div style={{ flex: 1 }}>
      <div
        style={{
          height: 50,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderBottomStyle: "solid",
          borderBottomWidth: 0.5,
          borderColor: "lightgray",
        }}
      >
        <div
          style={{
            flex: 1,
            textAlign: "left",
            paddingLeft: 10,
            fontWeight: "bold",
          }}
        >
          MASTERS SUMMARY
        </div>
        <div style={{ width: 300 }}>
          <InputText
            placeholder="SEARCH "
            style={{ width: "90%", height: 30 }}
            type="search"
            id="input"
            value={value || ""}
            onChange={(e) => onGlobalFilterChange(e)}
          />
        </div>
        <div style={{ width: 50 }}>
          <Button
            type="button"
            label="XLS"
            style={{
              height: 25,
              paddingLeft: 10,
              paddingRight: 10,
              fontSize: 12,
              color: NewTheme.greencolor,
              backgroundColor: "#fff",
              borderColor: NewTheme.greencolor,
            }}
            onClick={exportExcel}
            data-pr-tooltip="EXCEL"
          />
        </div>
        <div style={{ width: 50 }}>
          <Button
            type="button"
            label="PDF"
            style={{
              height: 25,
              paddingLeft: 10,
              paddingRight: 10,
              fontSize: 12,
              color: NewTheme.orangecolor,
              backgroundColor: "#fff",
              borderColor: NewTheme.orangecolor,
            }}
            onClick={exportPdf}
            data-pr-tooltip="PDF"
          />
        </div>
      </div>
      <TopbarMost
        backgroundColor={COLORSCHEMEA.THEMEFONT}
        textColor={COLORSCHEMEA.WHITECOLOR}
        cmp={overviewBar}
        noSidebar
        height={35}
      />
      <DataTable
        removableSort
        stripedRows
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        showGridlines
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        scrollHeight={height - 200}
        scrollable
        paginator
        filters={filters}
        onFilter={(e) => setFilters(e.filters)}
        rows={10}
        rowsPerPageOptions={[10, 25, 50, 100]}
        value={summary}
        loading={loading}
        style={{ fontSize: 15 }}
        size="small"
      >
        <Column
          style={{ width: "10%" }}
          showFilterMenu={false}
          field="server"
          header="Server"
          sortable
          filter
          body={(rowData) => (
            <span
              style={{ color: NewTheme.MainColor, textTransform: "uppercase" }}
            >
              {rowData.server}
            </span>
          )}
        />
        <Column
          style={{ width: "10%" }}
          showFilterMenu={false}
          field="username"
          header="Username"
          headerTooltip="Click to view overview"
          filter
          sortable
          body={(rowData) => (
            <>
              <Tooltip
                target=".username-tooltip"
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.9)",
                  borderRadius: "6px",
                  padding: "8px 12px",
                }}
                className="custom-tooltip"
                mouseTrack
                mouseTrackTop={10}
              />
              <span
                className="username-tooltip"
                onClick={() => goToOverview(rowData)}
                style={{
                  color: COLORSCHEMEA.THEMEFONT,
                  textDecoration: "underline",
                  textTransform: "uppercase",
                  cursor: "pointer",
                }}
              >
                {rowData.username}
              </span>
            </>
          )}
        />

        <Column
          sortable
          style={{ width: "10%" }}
          showFilterMenu={false}
          field="margin"
          header="Margin (%)"
          body={(rowData) => (
            <span>
              <NumberFormatIn value={parseFloat(rowData.margin)} />
            </span>
          )}
        />

        <Column
          sortable
          style={{ width: "10%" }}
          showFilterMenu={false}
          field="rent"
          header="Rent (%)"
          body={(rowData) => (
            <span>
              <NumberFormatIn value={parseFloat(rowData.rent)} />
            </span>
          )}
        />
        <Column
          sortable
          style={{ width: "10%" }}
          showFilterMenu={false}
          field="balance"
          header="Settlement Bal"
          body={(rowData) => (
            <span
              style={{
                gap: 10,
                display: "flex",
                color:
                  rowData.balance >= 0
                    ? COLORSCHEMEA.GREENCOLOR
                    : COLORSCHEMEA.REDCOLOR,
              }}
            >
              {/* <Button
                onClick={() => showBalanceModal(rowData)}
                icon={<RiEdit2Line size={10} color={COLORSCHEMEA.WHITECOLOR} />}
                style={{
                  backgroundColor: COLORSCHEMEA.THEMEFONT,
                  borderColor: COLORSCHEMEA.THEMEFONT,
                  height: 10,
                  width: 20,
                }}
              /> */}
              <NumberFormatIn value={parseFloat(rowData.balance)} />
            </span>
          )}
        />
        {/* <Column
          style={{
            width: "20%",
          }}
          sortable
          filter
          showFilterMenu={false}
          field="pl_margin"
          body={(rowData) => (
            <span
              style={{
                color:
                  rowData.pl_margin >= 0
                    ? NewTheme.greencolor
                    : NewTheme.redcolor,
              }}
            >
              <NumberFormatIn
                value={parseFloat(rowData.pl_margin).toFixed(0)}
              />
            </span>
          )}
          header="Net Profit/Loss"
        /> */}
        <Column
          style={{
            width: "10%",
          }}
          sortable
          filter
          showFilterMenu={false}
          field="pl"
          body={(rowData) => (
            <span
              style={{
                color:
                  rowData.pl >= 0 ? NewTheme.greencolor : NewTheme.redcolor,
              }}
            >
              <NumberFormatIn value={parseFloat(rowData.pl).toFixed(0)} />
            </span>
          )}
          header="Profit/Loss"
        />
      </DataTable>
      {visibleBalanceModal ? (
        <BalanceModal
          key={"BalanceModal"}
          usersId={modalData?.id}
          adminId={modalData?.admin_id}
          setVisible={() => setVisibleBalanceModal(false)}
          position={position}
          visible={visibleBalanceModal}
          deposit={modalData?.balance}
          clientUsername={modalData?.username}
          reload={() => {
            load_masters_summary();
          }}
        />
      ) : null}
    </div>
  );
}
